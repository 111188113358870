<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card class="elevation-x">
    <v-sheet color="grey lighten-3" class="pa-2">
      <v-row>
        <v-col cols="9">
          <v-text-field dense filled rounded placeholder="Recherche par nom" hide-details />
        </v-col>
      </v-row>
    </v-sheet>
    <v-divider />
    <v-list three-line class="py-0">
      <q-infinite-scroll ref="is">
        <template v-for="(item, i) in items">
          <v-list-item :key="i" three-line>
            <v-list-item-avatar size="50">
              <v-img :src="item.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
              <v-list-item-subtitle class="primary--text" v-text="item.job" />
              <v-list-item-subtitle v-text="item.address" />
            </v-list-item-content>
            <v-list-item-action class="align-self-center flex-row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: onAction }">
                  <v-btn icon v-on="onAction" @click.stop="call(item.phone)">
                    <font-awesome-icon fixed-width size="lg" :icon="['fal', 'phone']" class="primary--text" />
                  </v-btn>
                </template>
                <span>Appeler le patient</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on: onAction }">
                  <v-btn icon v-on="onAction" @click.stop="sendmail(item.email)">
                    <font-awesome-icon fixed-width size="lg" :icon="['fal', 'at']" class="primary--text" />
                  </v-btn>
                </template>
                <span>Envoyer un e-mail</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < items.length" :key="'divider-'+i" class="my-0" />
        </template>
        <!--        <template v-slot:loading>-->
        <!--          <v-progress-linear color="primary" indeterminate height="4" />-->
        <!--        </template>-->
      </q-infinite-scroll>
    </v-list>
  </v-card>
</template>

<script>
  import {QInfiniteScroll} from 'quasar'
  import contactMethods from '@/mixins/contactMethods'

  export default {
    name: 'MedicalTeamList',
    components: {QInfiniteScroll},
    mixins: [contactMethods],
    data: () => ({
      items: [
        {
          name: 'Jean-Baptiste Vercasson',
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          job: 'Médecin',
          phone: '0659624015',
          address: '122 Avenue Léo Lagrange, 13530 Trets',
          email: 'jbvercasson@e-synaps.com',
          MyDoctor: true
        },
        {
          name: 'Guillaume Paut',
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          job: 'Infirmier',
          phone: '0628623520',
          address: '15 rue de la Grange, 69000 Lyon',
          email: 'gpaut@e-synaps.com',
          MyDoctor: false
        },
        {
          name: 'Alexandre Leborgne',
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          job: 'Chirurgien',
          phone: '0625425012',
          address: '15 rue de la ville, 13120 Gardanne',
          email: 'aleborgne@e-synaps.com',
          MyDoctor: false
        }
      ]
    })
  }
</script>
